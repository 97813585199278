@import '../../../../../../shared/styles/variable.scss';

.verticalNavGroup {
  color: #000;
  height: 40px;
  margin-top: 1px;
  margin-bottom: 1px;
  padding-right: 12px;
  font-weight:900;
  font-size: 18px;
  cursor: pointer;
  text-decoration: none;
  white-space: nowrap;
  transition: all 0.4s ease;
  display: flex;
  align-items: center;
}