@import '../../../shared/styles/variable';

.customerHeaderInputView {
  max-width: 230px;

  // @include media-breakpoint-up(sm) {
  //   max-width: 150px;
  // }
}
.formControl{
  width: 110% !important;
}
.namefilter{
  width: 175% !important;
}
.panfilter{
  width: 100% !important;
  margin-left: 160px;
}
.customerHeaderLeft{
  margin-left: 10px;
  text-align: start !important;
  [dir='rtl'] & {
    padding-right: 0;
    padding-left: 10px;
  }

}
.customerHeaderRight {
  // width: 290px;
  margin-left: 10px;
  text-align: end !important;
  [dir='rtl'] & {
    padding-left: 0;
    padding-right: 10px;
  }
}
.tabtn {
  font-weight: bold !important;
  color: #0fafcd !important;
}
.btn-danger:disabled{
  color: #fff !important;
}
.btn-warning:disabled  {
  color: #000 !important;
}
.customerHeaderPagination {
  padding-left: 12px;
  padding-right: 2px;

  [dir='rtl'] & {
    padding-left: 2px;
    padding-right: 12px;
  }
}
.orderActionButtons {
  justify-content: space-between;
  display: flex;
}
.orderActionButtons button{
  margin-right: 5px !important;

}
.customerbuttoncolor {
  color: #fff !important;
}
.stateAlertColor {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}
.orderId {
  text-decoration: underline;
  color: $primary;
}

.dropdownToggleBtn {
  color: inherit;
  text-align: left;
  font-size: $font-size-base;

  [dir='rtl'] & {
    text-align: right;
  }

  &:after {
    display: none;
  }
}

.appDropdownMenu {
  font-size: $font-size-base;
}

.badge {
  padding: 3px 8px;
  color: $white;
  background-color: #52c41a;
  width: 45px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  height: 18px;
  border-radius: 10px;
  font-size: $font-size-sm;

  [dir='rtl'] & {
    margin-right: 0;
    margin-left: 8px;
  }
}

.appIconBtn {
  background-color: $component-bg;
  border-color: rgba(0, 0, 0, 0.12);
}
.select-search-input {
  height: 40px !important;
}
