@import '../../../../shared/styles/variable';

.appLayout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: $body-bg;

  &.appMainFixedFooter {
    padding-bottom: 42px;
  }

  &.boxedLayout {
    @include media-breakpoint-up(xxl) {
      margin-left: auto;
      margin-right: auto;
      max-width: 1360px;
      border-left: solid $border-width $border-color;
      border-right: solid $border-width $border-color;

      & .appMainSidebar {
        position: sticky;
      }

      & .appLayoutMain {
        margin-left: 0;
        width: 100%;

        [dir='rtl'] & {
          margin-right: 0;
        }
      }
    }
  }

  &.framedLayout {
    @include media-breakpoint-up(xxl) {
      padding: 20px;
      background-color: $primary;

      & .appDefaultLayout {
        border-radius: 12px;
        background-color: $body-bg;
        overflow: hidden;
        max-height: calc(100vh - 40px);
        box-shadow: $card-box-shadow;
        flex: inherit;
      }

      & .appMainSidebar {
        position: sticky;
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;

        [dir='rtl'] & {
          border-radius: 0 12px 12px 0;
        }
      }

      & .appHeader {
        border-top-right-radius: 12px;

        [dir='rtl'] & {
          border-top-right-radius: 0;
          border-top-left-radius: 12px;
        }
      }

      & .appFooter {
        border-bottom-right-radius: 12px;

        [dir='rtl'] & {
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 12px;
        }
      }

      & .appLayoutMain {
        margin-left: 0;
        width: 100%;

        [dir='rtl'] & {
          margin-right: 0;
        }
      }

      & .appSidebarScrollbar,
      & .mainContentScroll {
        height: calc(100vh - 110px) !important;
      }

      & :global(.apps-container) {
        height: calc(100vh - 212px);
      }
    }
  }
}

.appDefaultLayout {
  flex: 1;
  display: flex;
  position: relative;
}

.appLayoutMain {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  transition: all 0.5s ease 0s;
  width: 100%;

  @include media-breakpoint-up(xl) {
    margin-left: 240px;
    width: calc(100% - 240px);

    [dir='rtl'] & {
      margin-left: 0;
      margin-right: 240px;
    }
  }
}

.appMainSidebar {
  // border-right: 1px solid $border-color;
  position: relative;
  top: 0;
  left: 0;
  z-index: 3;
  width: 255px;
  max-height: 100vh;
  height: 100%;
  transition: all 0.4s ease 0s;

  [dir='rtl'] & {
    left: auto;
    right: 0;
    border-right: none;
    border-left: 1px solid $border-color;
  }

  @include media-breakpoint-up(xl) {
    position: fixed;
  }

  &:global(.sidebarImgBackground) {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: inherit;
      z-index: 1;
      opacity: 0.5;

      [dir='rtl'] & {
        left: auto;
        right: 0;
      }
    }

    & > div {
      position: relative;
      z-index: 3;
    }
  }
}

.appMainSidebarInner {
  padding-top: 22px;
  display: flex;
  flex-direction: column;
}

.appLogoBorder{
  position: relative;
  justify-content: center;
  text-align: center;
  // Additional styles for the line
  &::after {
    content: '';
    display: block;
    border-bottom: 1px solid #ccc;
    margin-top: -3px; // Adjust the margin as needed
    opacity: 0.5;
  }
}
.appHeader {
   min-height: 56px;
   padding-left: 20px;
   padding-right: 20px;
   background-color: $card-bg;
   color: $text-color;
   border-bottom: $border-width solid $border-color;

   @include media-breakpoint-up(sm) {
     min-height: 70px;
   }

   @include media-breakpoint-up(md) {
     padding-left: 30px;
     padding-right: 30px;
   }

   .trigger {
     font-size: 24px;
     color: $text-color;
     cursor: pointer;

     &:hover, &:focus {
       color: $text-color;
     }
   }
 }

.dropdownToggleBtn {
  color: inherit;
  text-align: left;
  font-size: $font-size-base;

  [dir='rtl'] & {
    text-align: right;
  }

  &:after {
    display: none;
  }
}

.appSidebarScrollbar {
  padding-top: 20px;
  height: calc(100vh - 56px) !important;
  border-right: 1px solid $border-color;
  @include media-breakpoint-up(sm) {
    height: calc(100vh - 70px) !important;
  }
}

.mainContentScroll {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  max-height: calc(100vh - 56px);

  .appMainFixedFooter & {
    max-height: calc(100vh - 104px);
  }

  @include media-breakpoint-up(sm) {
    max-height: calc(100vh - 70px);

    .appMainFixedFooter & {
      max-height: calc(100vh - 118px);
    }
  }
}

.appSearchWrapper {
  margin-right: 8px;

  @include media-breakpoint-up(sm) {
    margin-right: 32px;
  }

  [dir='rtl'] & {
    margin-left: 8px;
    margin-right: 0;

    @include media-breakpoint-up(sm) {
      margin-left: 32px;
      margin-right: 0;
    }
  }
}

.backBtn {
  background: linear-gradient(
    -90deg,
    rgba(15, 175, 205, 0.6) 0%,
    rgba(15, 175, 205, 0.6) 1%,
    rgba(15, 175, 205, 0.10196) 100%
  );
  color: #000;
  padding: 12px 16px;
  border: transparent;
  border-radius: 27px;
  @include media-breakpoint-down(xl) {
   display: none;
  }
}

.mobileBackBtn{
  background: linear-gradient(
    -90deg,
    rgba(15, 175, 205, 0.6) 0%,
    rgba(15, 175, 205, 0.6) 1%,
    rgba(15, 175, 205, 0.10196) 100%
  );
  color: #000;
  padding: 3px 7px;
  border: transparent;
  border-radius: 27px;
}