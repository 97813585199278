/* #region date picker css */
.datePicker {
  width: 100%;
}
.datePicker svg {
  padding: 11px 10px !important;
  font-size: 15px;
}
.datePicker input {
  padding: 6px 10px 5px 35px !important;
}
.datePicker .react-datepicker-popper[data-placement^='bottom'] {
  z-index: 9 !important;
}
.datePicker .react-datepicker__close-icon::after {
  background-color: rgb(15, 175, 205) !important;
}

.react-datepicker__year-option
  .react-datepicker__navigation--years-upcoming:after {
  content: '▲';
  text-decoration: none;
  position: absolute;
  left: -5px;
  top: 10px;
  margin: 0px;
  color: var(--bs-blue);
  font-size: 16px;
  z-index: 99999999999999999999;
}
.react-datepicker__year-option
  .react-datepicker__navigation--years-previous:after {
  content: '▼';
  text-decoration: none;
  position: absolute;
  left: -5px;
  /* right: 0; */
  /* bottom: 10px; */
  margin: 0px;
  color: var(--bs-blue);
  font-size: 16px;
  z-index: 99999999999999999999;
}
.react-datepicker__year-option .react-datepicker__navigation {
  text-indent: 1em !important;
}

/* changed Z index of Toggle Buttons to avoid overlap with datepicker calender */
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn.active,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:hover,
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn.active,
.btn-group > .btn:active,
.btn-group > .btn:focus,
.btn-group > .btn:hover {
  z-index: initial;
}
/* #endregion date picker css */
.resetBtn-section-title {
  display: flex;
  justify-content: space-between;
}
.resetBtn-section {
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-end;
}
.resetBtn {
  align-items: center;
  display: flex;
  border-radius: 20px;
  background-image: linear-gradient(
    to right top,
    #0fafcd,
    #7ac0e3,
    #b5d2f0,
    #e1e6f8,
    #ffffff
  );
  padding: 5px 15px;
  font-size: var(--header-btn-text);
}
.resetBtn button:hover {
  color: var(--black);
  background-color: var(--secondary-color-6);
}
.resetText {
  margin-left: 10px;
}
.commonFilterAction {
  display: flex;
  justify-content: flex-end;
}
@media (min-width: 992px) and (max-width: 1200px) {
  .commonFilterAction {
    margin-top: 10px;
  }
}
.accordion-button {
  font-size: 17px;
  font-weight: bold;
}
.icon-button-no-shadow {
  box-shadow: none !important;
}
.btn-outline-danger {
  box-shadow: none !important;
}
.btn-outline-success {
  box-shadow: none !important;
}
.btn-outline-info {
  box-shadow: none !important;
}
.customerbuttoncolor {
  color: #fff !important;
}