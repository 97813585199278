.tableItem {
  font-size: 13px;
  padding: 6px 8px !important;
  vertical-align: middle;

  &:first-child {
    padding-left: 20px !important;

    [dir=rtl] & {
      padding-left: 8px !important;
      padding-right: 20px !important;
    }
  }

  &:last-child {
    padding-right: 20px !important;

    [dir=rtl] & {
      padding-right: 8px !important;
      padding-left: 20px !important;
    }
  }
}
.misTableItem {
  font-size: 13px;
  padding: 6px 8px !important;
  vertical-align: middle;

  &:first-child {
    padding-left: 20px !important;

    [dir=rtl] & {
      padding-left: 8px !important;
      padding-right: 20px !important;
    }
  }

  &:last-child {
    padding-right: 20px !important;

    [dir=rtl] & {
      padding-right: 8px !important;
      padding-left: 20px !important;
    }
  }
}
.tableMailItem{
  font-size: 13px;
  padding: 10px 8px !important;
  vertical-align: middle;
}
.tableFolioItem {
  font-size: 13px;
  padding: 20px 8px !important;
  vertical-align: middle;

  &:first-child {
    padding-left: 20px !important;

    [dir=rtl] & {
      padding-left: 8px !important;
      padding-right: 20px !important;
    }
  }

  &:last-child {
    padding-right: 20px !important;

    [dir=rtl] & {
      padding-right: 8px !important;
      padding-left: 20px !important;
    }
  }
}

// .tableInvestItem{
//   display: flex;
//   justify-content: space-between;
//   width: 60%;
// }

.tableSalesItem {
  font-size: 13px;
  padding: 7px 8px !important;
  vertical-align: middle;

  &:first-child {
    padding-left: 20px !important;

    [dir=rtl] & {
      padding-left: 8px !important;
      padding-right: 20px !important;
    }
  }

  &:last-child {
    padding-right: 20px !important;

    [dir=rtl] & {
      padding-right: 8px !important;
      padding-left: 20px !important;
    }
  }
}

.investCategoryItem{
  padding: 2px;
  border: 1px solid #0fafcd;

}