@import '../../../../../shared/styles/variable.scss';

.appLogo {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  justify-content: flex-end;
  // align-items: center;

  & img {
    margin-top: 70px;
  }

}

@media (min-width: 1200px) and (max-width: 1500px){
  .appLogo {
  
    & img {
      margin-top: 70px;
      // height: 116px;
    }
  }
}