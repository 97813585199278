@import '../../../shared/styles/variable';

.tableHeading {
  font-size: 13px;
  padding: 8px;
  font-weight: $font-weight-bold;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2;
  background-color:#fff !important;
  &:first-child {
    padding-left: 20px;
    [dir=rtl] & {
      padding-left: 8px;
      padding-right: 20px;
    }
  }

  &:last-child {
    padding-right: 20px;

    [dir=rtl] & {
      padding-right: 8px;
      padding-left: 20px;
    }
  }
}
