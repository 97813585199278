@import '../../../shared/styles/variable.scss';
@import '../../../shared/styles/mixin.scss';

.auth {
  position: relative;

  & .main-content-view {
    padding: 20px;
  }
}

.authScroll {
  & [aria-label="scrollable content"],
  & [aria-label="scrollable content"] > div {
    height: 100% !important;
  }
}