@import '../../../../../shared/styles/variable.scss';

.appHeaderLogo {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  justify-content: flex-end;
  // align-items: center;

  & img {
    height: 120px;
  }

}

@media (min-width: 1200px) and (max-width: 1500px){
  .appHeaderLogo {
  
    & img {
      height: 116px;
    }
  }
}