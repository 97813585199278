@import '../../../../shared/styles/variable.scss';
@import '../../../../shared/styles/mixin.scss';

.appMainFooter {
  // padding: 20px 20px;
  color: $text-color;
  background-color: $component-bg;

  @include media-breakpoint-up(md) {
    // padding: 25px 32px;
  }

  & p {
    margin-bottom: 0;
  }
}
.appMainFooterLink {
  padding: 5px;

  & a {
    color: #000 !important;
    font-weight: bolder;
    font-size: 25px;
    text-decoration: none;
  }
}

.appMainFooterText {
  line-height: normal;

  & p {
    color: #0fafcd;
    font-size: 15px;
    font-weight: 800;
  }
} 

.borderTop,
.borderBottom {
  width: 100%;
  height: 9px;
  // background-color: #0fafcd;
  background: linear-gradient(to right, #0fafcd, #0fafcd4a);
}

.borderTop {
  top: 0;
}

.borderBottom {
  margin-top: 5px;
  bottom: 0;
}

@media screen and (max-width: 768px) {
  .appMainFooter {
    margin: 10px 0;
  }
}
@media (max-width: 1024px) {
  .appMainFooterText p {
    font-size: 12px;
  }
  .appMainFooterLink a {
    font-size: 20px;
  }
}
